import React from "react";
import { Box, Heading, VStack } from '@chakra-ui/react';
import { WORK_EXPERIENCES } from "../../constants/work";
import WorkCard from "./WorkCard";

const Work = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" py={8} px={4}>
      <Heading size="lg" mb={6} textAlign="center">
        Work Experiences
      </Heading>
      <VStack spacing={4} width="100%" maxW="container.md">
        {WORK_EXPERIENCES.map((work, index) => (
          <WorkCard key={index} work={work} />
        ))}
      </VStack>
    </Box>
  );
};

export default Work;
