
interface SingleAction {
  label: string;
  url: string;
  isDisabled?: boolean;
}

interface SingleProject {
  title: string;
  description: string;
  image: string;
  actions: SingleAction[];
  isGroup?: boolean;
}

/*
01. (G) NTU Mods
02. (I) NGS Living Pictures
03. (I) Othello Game
04. (G) FixedSense AI
05. (I) AutoML
06. (I) Task Master
07. (G) NTUSU Portal
08. (I) Buying Frenzy Backend
09. (I) CS50 AI
10. (G) MOBLIMA
11. (I) Chess Game
12. (I) CS50 Web
13. (G) PINTU App
14. (I) CS50 Games
15. (I) MVC Blogging Website
16. (G) ECommerce Website

More coming soon...
*/

export const PROJECTS_LIST: SingleProject[] = [
  // 1. NTU Mods
  {
    'title': 'NTU Mods',
    'isGroup': true,
    'description': `
NTU Module Management to help students plan their modules and timetable.
Used by over 3,000 students in NTU (metrics via Google Analytics) per June 2024.
This project is associated with me being the chairperson of NTU Student Union Information Technology Committee,
and is a collaboration project amongst some committee members,
involving other frontend/backend developers, UI/UX designers, and stakeholders.
My contribution spans mostly on backend and managerial aspects
(guiding the team, product requirements), with some frontend work.
Aimed to soon open-source the project by December 2024.
    `,
    'image': '/images/ntumods.png',
    'actions': [
      {
        'label': 'View Live Project',
        'url': 'https://ntumods.org'
      },
    ],
  },

  // 2. National Gallery Singapore Living Pictures
  {
    'title': 'National Gallery Singapore Living Pictures',
    'description': `
This is a volunteering project in a collaboration between NTU and National Gallery Singapore.
I contributed as Frontend Developer, where I created Living Pictures - a digital publication website,
in which NGS side can easily use and expand upon the template that I created.
The website showcases by frontend skills, particularly in React, TypeScript, and CSS.
The site used completely custom components, created from scratch, without the use of UI libraries.
    `,
    'image': '/images/ngs.png',
    'actions': [
      {
        'label': 'View Live Project',
        'url': 'https://ngs.michac789.com'
      },
      {
        'label': 'View Source Code',
        'url': 'https://github.com/michac789/ngs-living-pictures',
      }
    ],
  },

  // 3. Othello Game
  {
    'title': 'Othello (Desktop Game + AI Player)',
    'description': `
Fully functional Othello game complete with user interface, game logic, and AI player of 6 difficulty levels.
Simply click on the download link below to download the game and try it yourself!
    `,
    'image': '/images/placeholder.png',
    'actions': [
      {
        'label': 'Download Game',
        'url': 'TODO',
        'isDisabled': true,
      },
      {
        'label': 'View Demo',
        'url': 'TODO',
        'isDisabled': true,
      },
      {
        'label': 'View Source Code',
        'url': 'https://github.com/michac789/Othello',
      }
    ],
  },

  // 04. FixedSense AI
  {
    'title': 'FixedSense AI',
    'description': `
FIXEDSENSE AI is an AI-Powered real-time virtual assistant that enables the visually impaired to see the world by hearing.
This project made to the semi-finals of MLDA Deep Learning Week Hackathon 2022.
    `,
    'image': '/images/placeholder.png',
    'actions': [
      {
        'label': 'View Demo',
        'url': 'TODO',
        'isDisabled': true,
      },
      {
        'label': 'View Source Code',
        'url': 'https://github.com/michac789/MLDA-DLW-Hackathon-2022',
      },
    ],
  },

  // 05. AutoML
  {
    'title': 'Automated Machine Learning',
    'description': `
Ever thinking that machine learning is difficult?
That only some people can create machine learning models?
This project simplifies the process of creating machine learning models,
so people who can use a computer can simply create machine learning models
by just uploading their data and clicking a few buttons.
Automated machine learning will automatically chooses the best
models and hyperparameters for the user.
Unfortunately, due to the computational power and cost required to deploy this project,
it is not available online.
    `,
    'image': '/images/placeholder.png',
    'actions': [
      {
        'label': 'View Demo',
        'url': 'https://drive.google.com/drive/folders/1EcykN2br8xg_-GUYaZfnwcGQyhsptrPy'
      },
    ],
  },

  // 06. Task Master
  {
    'title': 'Task Master',
    'description': `
Web application to manage your TODO list.
Complete with authentication and persistent database.
The frontend is created purely with Vanilla JavaScript, HTML, and CSS,
showcasing my JS abilities without the use of frameworks.
Backend is created with Flask, a Python web framework,
complete with test cases and error handling.
    `,
    'image': '/images/taskmaster.png',
    'actions': [
      {
        'label': 'View Live Project',
        'url': 'https://ngs.michac789.com'
      },
      {
        'label': 'View Source Code',
        'url': 'https://github.com/michac789/ngs-living-pictures',
      }
    ],
  },

  // 07. NTUSU Portal
  {
    'title': 'NTU Student Union Portal',
    'isGroup': true,
    'description': `
Portal for NTU Student Union offering various services for undergraduate students in NTU.
This project is associated with me being the chairperson of NTU Student Union Information Technology Committee,
leading a team of 12 other people (AY2022-2023) to create this website.
    `,
    'image': '/images/ntusuportal.png',
    'actions': [
      {
        'label': 'View Live Project',
        'url': 'https://app.ntusu.org'
      },
    ],
  },

  // 08. Buying Frenzy Backend
  {
    'title': 'Buying Frenzy Backend',
    'description': `
Robust backend REST API server for a restaurant (NestJS with 
PrismaORM),
including authentication, error handling, and test cases,
deployed by AWS Elastic Beanstalk.
    `,
    'image': '/images/placeholder.png',
    'actions': [
      {
        'label': 'View Project',
        'url': 'https://github.com/michac789/Buying-Frenzy-Backend',
      }
    ],
  },

  // 09. CS50 AI
  {
    'title': 'CS50 AI',
    'description': `
This repository is based on the course CS50's Introduction to Artificial Intelligence with Python.
It contains my solution for the 12 different projects offered from this course.
Refer to the README.md inside the repository for more information.
    `,
    'image': '/images/placeholder.png',
    'actions': [
      {
        'label': 'View Demo',
        'url': 'TODO',
        'isDisabled': true,
      },
      {
        'label': 'View Source Code',
        'url': 'https://github.com/michac789/CS50-AI',
      },
    ],
  },

  // 10. MOBLIMA
  {
    'title': 'MOBLIMA',
    'description': `
MOBLIMA is an application to computerize the processes of making online booking and purchase of movie tickets, listing of movies and sale reporting.
It will be used by the moviegoers and cinema staff.
This serves as a final project for SC2002 Object Oriented Programmign module and consists of 30% of our mark.
    `,
    'image': '/images/placeholder.png',
    'actions': [
      {
        'label': 'View Source Code',
        'url': 'https://github.com/michac789/SC2002-MOBLIMA',
      },
    ],
  },
  
  // 11. Chess Game
  {
    'title': 'Chess Game',
    'description': `
2-Player Chess Game with GUI.
This project is a part of my research project in NTU, where I am researching about computer chess AI.
This project is the first phase of the project, where I created a simple 2-player chess game with GUI.
    `,
    'image': '/images/placeholder.png',
    'actions': [
      {
        'label': 'View Source Code',
        'url': 'https://github.com/michac789/python-chess/tree/main/phase2',
      }
    ],
  },

  // 12. CS50 Web
  {
    'title': 'CS50 Web',
    'description': `
This repository is based on the course CS50's Web Programming with Python and JavaScript.
It contains the solution for the 5 different projects offered from this course,
including a Django-based e-commerce website, a Django-based social network website, and a React-based front-end for a Django backend.
It also contains the lecture source code and notes.
    `,
    'image': '/images/placeholder.png',
    'actions': [
      // {
      //   'label': 'View Live Project',
      //   'url': 'https://ngs.michac789.com'
      // },
      {
        'label': 'View Source Code',
        'url': 'https://github.com/michac789/CS50-Web',
      }
    ],
  },

  // 13. PINTU App
  {
    'title': 'PINTU App',
    'isGroup': true,
    'description': `
This is a project for PINTU, which is Indonesian student association in NTU.
It is a collaborative project, created by over 20 people of developers.
My personal contribution is on the backend of LAPAK, a marketplace feature in the app.
In AY2023-2024, I became Technology Director (Backend & DevOps) of PINTU,
whose main task is leading the backend and devops team.
    `,
    'image': '/images/placeholder.png',
    'actions': [
      {
        'label': 'View Live Project',
        'url': 'https://app.pintusingapura.org',
      },
      // {
      //   'label': 'View Source Code',
      //   'url': 'https://github.com/michac789/ngs-living-pictures',
      // }
    ],
  },


//   // DO NOT REMOVE THIS
//   {
//     'title': 'More Coming Soon!',
//     'description': `
// This website is still under construction, over 10 more projects are coming very soon (to be displayed)!
// This website is scheduled to be further updated on 17 July 2024 with a few more projects to come,
// and fixes on various links and project demos.
//     `,
//     'image': '/images/placeholder.png',
//     'actions': [
//       // {
//       //   'label': 'View Live Project',
//       //   'url': 'https://ngs.michac789.com'
//       // },
//       // {
//       //   'label': 'View Source Code',
//       //   'url': 'https://github.com/michac789/ngs-living-pictures',
//       // }
//     ],
//   },
]
