
interface RecommendationLetter {
  name: string;
  link: string;
}

export const RECOMMENDATION_LETTERS: RecommendationLetter[] = [
  {
    name: 'Syngenta',
    link: 'https://michaelac-portfolio-public.s3.ap-southeast-1.amazonaws.com/recommendation-letter-syngenta.pdf',
  },
  {
    name: 'SLB (Schlumberger)',
    link: 'https://michaelac-portfolio-public.s3.ap-southeast-1.amazonaws.com/recommendation-letter-slb.pdf',
  },
  {
    name: 'NGS (National Gallery Singapore)',
    link: 'https://michaelac-portfolio-public.s3.ap-southeast-1.amazonaws.com/recommendation-letter-ngs.pdf',
  },
]
