
export interface WorkExperience {
  company: string;
  position: string;
  location: string;
  duration: string;
  description: string[];
  image?: string;
  recommendationLetter?: string;
};

export const WORK_EXPERIENCES: WorkExperience[] = [
  {
    company: 'Continental',
    position: 'R&D Software Engineer Intern',
    location: 'Boon Keng, Singapore (Hybrid)',
    duration: 'Jan 2025 - May 2025',
    description: [
      'Software and Central Technologies (SCT) Department'
    ],
    image: 'https://michaelac-portfolio-public.s3.ap-southeast-1.amazonaws.com/images-experience/company-continental.jpg',
    recommendationLetter: 'https://www.linkedin.com/in/kevinzhaodev/detail/recommendation/write/670000001/',
  },
  {
    company: 'SLB (Schlumberger)',
    position: 'Software (Data) Engineer Intern',
    location: 'Benoi Sector, Singapore (Hybrid)',
    duration: 'May 2024 - Aug 2024',
    description: [
      'Q&T Department - Data Engineering Team'
    ],
    image: 'https://michaelac-portfolio-public.s3.ap-southeast-1.amazonaws.com/images-experience/company-slb.jpg',
  },
  {
    company: 'Syngenta',
    position: 'Full-Stack Engineer Intern',
    location: ' Science Park, Singapore (Hybrid)',
    duration: 'Nov 2023 - May 2024',
    description: [
      'APAC Operational Technology Team',
      'Full-Time (Nov 2023 - Jan 2024), Part-Time (Jan 2024 - May 2024)',
    ],
    image: 'https://michaelac-portfolio-public.s3.ap-southeast-1.amazonaws.com/images-experience/company-syngenta.jpg',
  },
  {
    company: 'Glints',
    position: 'Software Engineer Intern',
    location: 'One North, Singapore (Hybrid)',
    duration: 'May 2023 - Nov 2023',
    description: [
      'Shared Services Team (May 2023 - July 2023)',
      'Managed Talent Team (July 2023 - Nov 2023)',
    ],
    image: 'https://michaelac-portfolio-public.s3.ap-southeast-1.amazonaws.com/images-experience/company-glints.jpg',
  },
]
