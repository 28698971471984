import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Image, Heading, Text } from '@chakra-ui/react';
import { RECOMMENDATION_LETTERS } from '../../constants/letter';

const BUTTONS = [
  {
    'path': '/projects',
    'text': 'Personal Projects',
  },
  {
    'path': '/cca',
    'text': 'Co-Curricular Activities',
  },
  {
    'path': '/work',
    'text': 'Internship Experiences',
  }
]

const Home: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(path);
  };

  return (
    <Box>
      <Heading as='h1' size='2xl' mb={4}>
        Hi, I'm Michael Andrew Chan
      </Heading>
      <Heading as='h2' size='lg' mb={4}>
        Software Engineer (based in Singapore)
      </Heading>
      <Text fontSize='lg' mb={4}>
        Welcome to my personal website.
        The purpose of this website is to showcase my projects and experiences
        related to software engineering in a more detailed manner than my resume.
        This website is still under construction and may not have the best ui yet,
        but always check back for updates as I am rapidly improving this website.
      </Text>
      <Text fontSize='lg' mb={8}>
        Phone: (+65) 9428 9104 | Email: mich0107@e.ntu.edu.sg
      </Text>
      <Image src='/images/photo.jpg' alt='Michael Andrew Chan' mb={8} width='200px' />

      <Box mb={12} p={8} borderWidth={1} borderRadius='lg' boxShadow='md'>
        <Flex direction='column' align='center' mb={6}>
          <Heading as='h3' size='lg' mb={2}>Recommendation Letters</Heading>
          <Text fontSize='md'>
            Discover what others have to say about my work and impact!
          </Text>
        </Flex>
        <Flex justify='center' gap={4}>
          {RECOMMENDATION_LETTERS.map((letter, index) => (
            <Button
              key={index}
              colorScheme='teal'
              variant='solid'
              onClick={() => window.open(letter.link, '_blank')}
            >
              {letter.name}
            </Button>
          ))}
        </Flex>
      </Box>

      {BUTTONS.map((button, index) => (
        <Button
          key={index}
          colorScheme='teal'
          variant='outline'
          width='200px'
          onClick={() => handleClick(button.path)}
          mr={2}
        >
          {button.text}
        </Button>
      ))}
    </Box>
  );
};
export default Home;
